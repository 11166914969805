<template>
  <div>
    <page-title-card
      :page-title="t('title')"
      :page-description="`${t('description')} ${appName}`"
    >
      <template #button>
        <n-button
          id="button-new"
          type="primary"
          size="md"
          round
          block
          @click.native="handleNew"
          :visible_for_permissions="[{ actions: ['POST'], path: '/profiles'}]"
        >
          <i slot="label">
            <svgicon class="icon" icon="icon-new" />
          </i>
          {{ t("New") }}
        </n-button>
      </template>
    </page-title-card>
      <b-card-body style="padding: 0px">
        <paginated-table
          :rows="rows"
          :columns="columns"
          :actions="actions"
          :search="['profile_definition_id']"
          @item-edit="handleEdit"
          @item-delete="handleDelete"
        />
      </b-card-body>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
//import { PROFILES } from "@/configs/tablesData";
import PaginatedTable from "@/components/Tables/PaginatedTable";
import { YesNoListFormatter } from '@/components/Tables/Formatters';
import General from "@/mixins/General";
import PageTitleCard from "../../../components/AsmConfigurations/PageTitleCard";

export default {
  name: "profiles",
  components: {
    PaginatedTable,
    PageTitleCard
  },
  mixins: [General],
  data() {
    return {
      columns: [
        { prop: "profile_definition_id", label: this.t("PROFILES ID"), minWidth: 130},
        { prop: "services", label: this.t("services"), minWidth: 180, formatter: YesNoListFormatter},
        { prop: "language_id", label: this.t("languages"), minWidth: 100, formatter: YesNoListFormatter}
      ],
      actions: {
        minWidth: 170,
        label: this.t("Actions"),
        fixed: "right",
        items: [
            {
                type: "warning",
                icon: "now-ui-icons business_badge",
                event: "item-edit",
                action: this.t("Edit")
            },
            {
                type: "danger",
                size: "sm",
                icon: "now-ui-icons ui-1_simple-remove",
                action: this.t("Remove"),
                confirm: true,
                event: "item-delete",
                visibleIf: (row) => {
                  return row['profile_definition_id'] !== "SystemDefaultProfile"
                },
                visible_for_permissions: [{actions: ["DELETE"], path: "/profiles/.*"}]
            }
        ]
      }
    };
  },
  async created() {
    await this.getProfiles();
    if (!this.defaultAdministratorLanguageId) {
      this.getAdministrator(this.managerId);
    }
    this.setProfile({ isSetDefault: true });
  },
  methods: {
    ...mapActions("generalManagement/administrators",["getAdministrator"]),
    ...mapActions("ispServices/profiles", ["getProfiles", "deleteProfile"]),
    ...mapMutations("ispServices/profiles", ["setProfile"]),
    handleNew() {
      this.$router.push({
        name: `ISP Services / Configuration / Profiles / New`,
      });
    },
    handleEdit(data) {
      this.$router.push({
        name: `ISP Services / Configuration / Profiles / Edit`,
        params: { id: data.row.profile_definition_id },
      });
    },
    handleDelete(data) {
      this.deleteProfile(data.row.profile_definition_id);
    },
  },
  computed: {
    ...mapState("auth",["managerId"]),
    ...mapState("generalManagement/administrators", ["defaultAdministratorLanguageId"]),
    ...mapState("ispServices/profiles", ["profiles"]),
    rows: {
      get() {
        const profiles = _.cloneDeep(this.profiles);
        if (!profiles) return;
        let state;
        profiles.forEach((profile) => {
          profile.language_id = profile.profile_i18n.map((obj) => {
            return { name: obj.language_id, state: true };
          });
          profile.services = {};
          Object.keys(profile.services_configuration).forEach((serviceName) => {
            if (serviceName === "general") state = true;
            else state = profile.services_configuration[serviceName] ?
              profile.services_configuration[serviceName].enabled : false;
            profile.services[serviceName] = {
              name: this.t(this.translateHandler(serviceName, "_", " ")),
              state,
            };
          });
        });
        return profiles;
      },
      set(newRules) {},
    },
    appName() {
      return this.$store.getters['customProvider/appName'];
    }
  }
};
</script>
